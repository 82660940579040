<template>
  <div class="tablePage" ref='tablePage'>
    <template v-if="!type || type != 'wxcollect'">
      <el-table ref="multipleTable" :data="defaultTable" border style="width:100%" :height="isPage ? '94%' : '100%'"
        v-loading="loading" element-loading-text="拼命加载中" @row-click="onRowClick" @row-dblclick="doubleClick" element-loading-spinner="el-icon-loading"
        element-loading-background="transparent" :highlight-current-row="true">
        <template v-for="(item, index) in tableArray">
          <el-table-column :prop="item.key" :label="item.name":formatter="item.formatter" show-overflow-tooltip :min-width="item.width"
            v-if="item.key != 'operate'">
          </el-table-column>
          <el-table-column v-else :min-width="item.width">
            <template slot="header" slot-scope="scope">
              <label>勾选</label>
            </template>
            <template slot-scope="scope">
              <div @click.stop>
                <el-checkbox v-model="scope.row.checked" @change="handleCheckOneChange(scope.row)"></el-checkbox>
              </div>
            </template>
          </el-table-column>

        </template>
      </el-table>

      <el-pagination background layout="prev, pager, next" :total="total" v-if="isPage" :page-size="pageSize"
        :current-page="currentPage" @current-change="currentMethod" @prev-click="currentMethod"
        @next-click="currentMethod">
      </el-pagination>
    </template>
    <template v-else>
      <!-- 手写表格挣扎下 -->
      <u-table ref="gridTable" :empty-text="description" :height="tableheight" :row-height="25" use-virtual show-header-overflow="title"
        show-overflow="title" @table-body-scroll="scrollMethod" @row-dblclick="doubleClick" border  :data-changes-scroll-top="isTop">
        <!-- :slots="item.slots" -->
        <template v-for="(item,index) in tableArray" >
          <u-table-column :show-overflow-tooltip="true"  :slots="item.slots" :prop="item.field" :label="item.title" 
            :min-width="item.width"  v-if="item.field != 'operate'"/>
      
        <u-table-column
         :label="item.title"
        :min-width="item.width" v-else>
        <template slot-scope="scope">
          <input v-model="scope.row.operate" type="checkbox" @change="handleCheckOneChange(scope.row)"/>
        </template>
      </u-table-column>
    </template>

      </u-table>
      <!-- :width="item.width" -->
      <!-- <ux-grid border ref="gridTable" show-header-overflow="title" show-overflow="title" :show-summary="true"
        :height="tableheight" :edit-config="{ trigger: 'click', mode: 'row' }" :columns="tableArray"
        @row-dblclick="doubleClick" @table-body-scroll="scrollMethod"></ux-grid> -->
      <!-- @selection-change="handleCheckOneChange"  @select="handleCheckOneChange" -->
    </template>
  </div>
</template>
<script>
  import { mapState } from 'vuex';
  import { Loading } from 'element-ui';
  export default {
    data() {
      return {
        isTop: true, //是否回到顶部
        timer: null,//定时器
        checkAll: [],//多选框
        tableListH: 0,//列表高度
        scrollTop: 0,
        load: false,//是否加载动画
        tableheight: 0,//高度
        screenWidth: 0, //屏幕宽度
        initScrollMore:false,//是否下拉加载

        //checkList: [], //复选框初始化
      }
    },
    props: {
      loading: {
        type: Boolean,
        default: false,
      },
      total: {
        type: Number,
        default: 0
      },
      pageSize: {
        type: Number,
        default: 0
      },
      currentPage: {
        type: Number,
        default: 0
      },
      isPage: {
        type: Boolean,
        defualt: false
      },
      // tableH: {
      //   type: String,
      //   default: ''
      // },
      tableArray: {
        type: Array,
        default: []
      },
      type: {
        type: String,
        default: ''
      },
      defaultTable: {
        type: Array,
        default: []
      },

      description: {
        type: String,
        default: '暂无数据~'
      }
    },
    watch: {
      screenWidth: {
        deep: true,
        handler(newValue, oldValue) {
          //console.log('监听进来？？？')
          this.screenWidth = newValue
          //console.log('this.$refs.tablePage.offsetHeight', this.$refs.tablePage.offsetHeight)
          this.tableheight = this.$refs.tablePage.offsetHeight
          // if (!this.timer) {
          //   this.screenWidth = newValue
          //   this.timer = true
          //   this.countMethod()
          // }
        },
      },
    },
  
    created() {
      let that = this;
      //console.log('defaultTable', this.type)
    },
    mounted() {
      let that = this;
      //console.log('defaultTable',that.$refs.tablePage.offsetHeight)
      if (that.$refs.gridTable) {
        that.tableheight = that.$refs.tablePage.offsetHeight
       // console.log('没进来???', that.tableheight)
        // 滚动到底部
        // that.$refs.gridTable.scrollToRow(this.defaultTable[this.defaultTable.length - 1])
        window.addEventListener('resize', function () {
          console.log('resize---->', document.body.clientWidth,that.$refs.tablePage,that.$refs?.tablePage?.offsetHeight)
          that.screenWidth = document.body.clientWidth
          that.tableheight = that.$refs?.tablePage?.offsetHeight
        })
      }
      //that.$refs.gridTable&&that.$refs.gridTable.scrollToRow(this.defaultTable[this.defaultTable.length - 1])
      // 定时器
      //that.initScroll()
    },
    activated() {
      //console.log('table,,,',this.defaultTable)
    },
    deactivated() {
      //console.log('this.$refs.xnTable1[0].bodyWrapper',this.$refs.multipleTable.bodyWrapper.scrollTop) 
      //  console.log('页面关闭（路由跳转）时清除定时器')
      // 页面关闭（路由跳转）时清除定时器
      this.clearMethod()
    },
    methods: {
      //滚动事件
      scrollMethod({ scrollTop, scrollLeft, table, judgeFlse }) {
       // console.log('scrollTop', scrollTop, scrollLeft,judgeFlse,table)
         !judgeFlse&&(this.initScrollMore=false)
        if(judgeFlse&&!this.initScrollMore){
          this.scrollTop=scrollTop
          this.initScrollMore=true
          this.$emit('loadMore')
        }

      },
      gridLoad(data) {
        data ? (this.load = Loading.service({
          lock: true,
          text: 'Loading',
          spinner: 'el-icon-loading',
          background: 'transparent'
        })) : (this.load && this.load.close())
      },

      //表格赋值-
      async setData(data, index) {
        let that = this;
       // console.log('还是这里啊啊啊啊', data,that.isTop,this.scrollTop)
        let n = await that.$refs.gridTable.reloadData(data)
      },
      //修改表格
      updateMwthod() {
        this.$refs.gridTable.toggleAllSelection()
      },
      //滚动到顶部
      initTop() {
        // console.log('我进来了？')
        this.$refs.gridTable.pagingScrollTopLeft(0)
      },
      //滚动定时器
      // initScroll() {
      //   let divData=this.$refs.gridTable.bodyWrapper
      //   console.log('this.$refs.gridTable',this.$refs.gridTable)
      //     if (divData.clientHeight + divData.scrollTop == divData.scrollHeight) {

      //        console.log('到顶了?',this.timer)
      //      }
        // this.timer&&clearInterval(this.timer)
        // this.$refs.gridTable.pagingScrollTopLeft(this.scrollTop)
        // this.timer=setInterval(() => {
        //    // 元素自增距离顶部1像素
        //     // divData.scrollTop += 100;
        //     this.scrollTop+=100;
        //     this.$refs.gridTable.pagingScrollTopLeft(this.scrollTop)
        //     // 判断元素是否滚动到底部(可视高度+距离顶部=整个高度)
        //   //   if (divData.clientHeight + divData.scrollTop == divData.scrollHeight) {

        //   //    console.log('到顶了?',this.timer)
        //   //  }
        //   }, 100); 
      // },
      //row双击
      doubleClick(row, column, event) {
        //console.log('双击没用？？')
        event.preventDefault();
        event.stopPropagation();
        this.clearMethod()
        this.$emit('doubleClick', row)
      },
      onRowClick(row, column, event){
        event.preventDefault();
        event.stopPropagation();
        this.clearMethod()
        this.$emit('onRowClick', row,column,event)
      },

      //清除定时器
      clearMethod() {
        //  console.log('1111clear',this.timer)
        //  clearInterval(this.timer)
        //  this.timer=null;
      },
      handleCheckOneChange(val) {
        //console.log('勾选val',val)
        // let data= this.defaultTable.filter(item=>item.checked)
        console.log('勾选data',val,this.$refs.gridTable.getCheckboxRecords())
        this.$emit('checkMethod', val)
        return this.$refs.gridTable.getCheckboxRecords()
        // console.info('check one change is ', val,this.defaultTable)
      },
      currentMethod(index) {
        this.$emit('currentMethod', index)
      }
    }

  }
</script>
<style lang="less" scoped>
  .tablePage {
    width: 100%;
    height: 100%;
    /* margin-top:14px; */
    overflow: hidden;
  }

  .tableHeader {
    width: 100%;
    border: 1px solid #DCDCDC;
    border-bottom: 0;

    li {
      line-height: 45px;
      background-image: linear-gradient(#FFFFFF, #F2F3F5, );
      border-right: 1px solid #DCDCDC;
      border-bottom: 1px solid #DCDCDC;
      opacity: 0.9;

      &:after {
        width: 100%;

      }
    }

    li:last-child {
      border-right: 0;
    }
  }

  .tableLi {
    border-left: 1px solid #DCDCDC;
    border-right: 1px solid #DCDCDC;
  }

  .tableList {
    width: 100%;

    li {
      /* flex-shrink:0; */
      line-height: 40px;
      border-right: 1px solid #DCDCDC;
      border-bottom: 1px solid #DCDCDC;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      padding: 0px 5px;
      box-sizing: border-box;
    }

    li:last-child {
      border-right: 0;
    }
  }

  /deep/ .el-table__body tr.current-row>td.el-table__cell {
    background-color: #F5F7FA;
  }

  /deep/ .elx-table.elx-editable .elx-body--column {
    height: 25px;
    line-height: 25px;
  }

  /deep/ .elx-table.border--full .elx-header--column {
    height: 25px;
    line-height: 25px;
    font-size: 14px;
    font-weight: bold;

    div {
      justify-content: center;
    }
  }

  /deep/ .elx-table {
    color: #000;

    .elx-table--body-wrapper {
      overflow-x: hidden;
    }

    .elx-cell {
      text-align: center;
      font-size: 14px;
      user-select: none;
      -webkit-user-select: none;

    }
  }

  /deep/ .el-table {
    color: #000;

    th.gutter {
      display: table-cell !important;
    }

    .cell {
      text-align: center;
      font-size: 13px;
      white-space: nowrap;
      padding: 0px;
      line-height: 25px;
      box-sizing: border-box;
      height: 25px;
      user-select: none;
      -webkit-user-select: none;
    }

    .el-table__cell {
      padding: 0;
    }

    .el-table__cell:first-child {
      .cell {
        padding: 0px;
      }
    }

    th.el-table__cell {
      background: linear-gradient(180deg, #FFFFFF, #F2F3F5);

      .cell {
        text-align: center;
        color: #000;
        font-size: 14px;
        padding: 0;
      }
    }

    th.el-table__cell:first-child {
      .cell {
        padding: 0;
      }
    }
  }

  /deep/ .el-loading-spinner {
    i {
      color: #000 !important;
    }

    .el-loading-text {
      color: #000 !important;
    }
  }

  /deep/ .el-table td.el-table__cell {
    border-color: #DCDCDC !important;
  }

  /deep/ .el-table th.el-table__cell.is-leaf {
    border-color: #DCDCDC !important;
  }

  /deep/ .el-table--border .el-table__cell:first-child .cell {
    padding-left: 0px;
  }

  /deep/ .el-loading-spinner {
    .el-loading-text {
      color: #fff;
    }

    i {
      color: #fff;
      font-size: 20px;
    }
  }

  .tableContent {
    height: 100%;
    width: 100%;
  }

  /deep/ .plTableBox {
    height: 100%;
  }

  /deep/ .plTableBox .el-table th {
    padding: 0 !important;
    line-height: 25px;
    height: 25px;
    color: #000;
    background-image: linear-gradient(#e8eaec, #e8eaec), linear-gradient(#e8eaec, #e8eaec);
    background-repeat: no-repeat;
    background-size: 1px 100%, 100% 1px;
    background-position: 100% 0, 100% 100%;
    background-color: #f8f8f9;
    font-weight: 700;
    border-bottom: 0 !important;
  }

  /deep/ .plTableBox .el-table td {
    height: 25px !important;
    line-height: 25px !important;
    color: #000;
    padding: 0 !important;
    font-size: 14px;
  }

  /* 自定义表格 */

  @media (min-width: 1660px) {
    .tableHeader {
      li {
        line-height: 35px;
      }
    }

    .tableList {

      li {
        line-height: 30px;

      }
    }

    /deep/ .el-table {
      .cell {
        font-size: 13px;

        /* -webkit-user-select:none; */
      }

      th.el-table__cell {
        .cell {
          font-size: 13px;
        }
      }
    }
  }
</style>